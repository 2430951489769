
class TrackingAbstract {
    // eslint-disable-next-line no-unused-vars
    trackEvent(eventGroup, eventName, parameters) {}
}

class TrackingHeap extends TrackingAbstract {
    trackEvent(eventGroup, eventName, parameters) {
        let heapCustomEventName = `${eventGroup} - ${eventName}`;

        parameters['Event Group'] = eventGroup;
        parameters['Event Name'] = eventName;
        window.heap && window.heap.track(heapCustomEventName, parameters);
    }
}

class BarkTracking {
    constructor(trackingInterface) {
        this._trackingInterface = trackingInterface;
    }

    logCustomEvent(eventGroup, eventName, parameters) {
        this._trackingInterface.trackEvent(eventGroup, eventName, parameters ?? {})
    }

}

window.barkTracking = new BarkTracking(new TrackingHeap());
